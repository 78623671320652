module.exports = {
  siteTitle: "Poles on Earth | Utility Poles AI Detection Service",
  siteDescription:
    "Poles on Earth detects utility poles in global scale from satellite images using Deep Learning techniques. Detects the current status (or past status) of utility networks. Compares and contrasts with past utility networks after natural disasters. Performs network analytics depending transmission and distribution of electricity and telecommunication development and maintenance.",
  siteKeywords:
    "Poles, Poles on Earth, Utility poles, object detection, computer vision, satellite images, deep learning, AI, artificial intelligence, telecommunication, electricity, lighting",
  siteUrl: "https://polesonearth.com",
  siteLanguage: "es-US",
  googleVerification: "",
  twitterHandle: "@TAGeoforce",
  heading: "Poles On Earth",
  subHeading:
    "Object Detection service for discovering utility poles using satellite images.",
  socialLinks: [
    {
      icon: "fa-github",
      name: "Github",
      url: "https://github.com/TA-Geoforce",
    },
    {
      icon: "fa-twitter",
      name: "Twitter",
      url: "https://twitter.com/TAGeoforce",
    },
    {
      icon: "fa-linkedin",
      name: "Linkedin",
      url: "https://www.linkedin.com/company/poles-on-earth",
    },
    {
      icon: "fa-envelope-o",
      name: "Email",
      url: "mailto:info@tageoforce.com",
    },
  ],
  colors: {
    green: '#64ffda',
    navy: '#485869',
    darkNavy: '#29323c',
  },
  schemaMarkup : {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Poles on Earth",
    description:
      "Object Detection service for discovering utility poles using AI satellite images.",
    telephone: "+30 2114144000",
    email: "sales@polesonearth.com",
    sameAs: "",
    image: "./static/android-chrome-192x192.png",
    url: "https://polesonearth.com",
    priceRange: "$",
    address: [
      {
        "@type": "PostalAddress",
        addressLocality: "Nea Ionia",
        addressRegion: "Greece",
        streetAddress: "El. Venizelou 75",
        postalCode: "14231",
      },
    ],
  }
}
