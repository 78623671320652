import React from "react"

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Poles on Earth</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M42.72881,50.00839L36.86294,50.05306L36.70486,64.04885L31.54167,64L31.54167,33.3L42.29167,33.3C48.69167,33.3 52.81362,36.15488 52.69167,41.65C52.56972,47.14512 47.80661,49.97576 42.72881,50.00839zM36.59167,46.2L42.24167,46.2C45.79167,46.2 47.69167,44.85 47.69167,41.95C47.69167,39.05 45.79167,37.8 42.24167,37.8L36.59167,37.8L36.59167,46.2z"
          fill="#64FFDA"
          id="svg_2"
        ></path>
        <polygon
          id="Shape"
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
)

export default IconLogo
