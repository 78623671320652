import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import config from "../../config"
import packageInfo  from "../../package.json"
import androidIcon192x192 from "../assets/img/favicons/android-chrome-192x192.png"
import androidIcon512x512 from "../assets/img/favicons/android-chrome-512x512.png"
import appleIcon from "../assets/img/favicons/apple-touch-icon.png"
import favicon16x16 from "../assets/img/favicons/favicon-16x16.png"
import favicon32x32 from "../assets/img/favicons/favicon-32x32.png"
import favicon from "../assets/img/favicons/favicon.ico"
import msIcon150x150 from "../assets/img/favicons/mstile-150x150.png"
import safariPinnedTab from "../assets/img/favicons/safari-pinned-tab.svg"

//TODO change the href to be dynamically
const SEO = ({ metadata, titlePage, image }) => (
  <Helmet>
    <html lang="en" prefix="og: http://ogp.me/ns#" />
    <title itemProp="name" lang="en">
      {config.heading} | {titlePage}
    </title>
    <link rel="preload"/>
    <link rel="shortcut icon" href={favicon} />
    <link rel="canonical" href={process.env.HOST} />
    <meta charSet="utf-8"/>
    <meta name="generator" content={packageInfo.version}></meta>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <meta name="description" content={metadata.description} />
    <meta name="keywords" content={config.siteKeywords} />
    <meta name="google-site-verification" content={config.googleVerification} />
    <meta property="og:title" content={metadata.title} />
    <meta property="og:description" content={metadata.description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={metadata.siteUrl} />
    <meta property="og:site_name" content={metadata.title} />
    <meta property="og:image" content={`${process.env.HOST}${image.images.fallback.src}`} />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:locale" content={config.siteLanguage} />
    <meta name="image" content={`${process.env.HOST}${image.images.fallback.src}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content={metadata.siteUrl} />
    <meta name="twitter:site" content={config.twitterHandle} />
    <meta name="twitter:creator" content={config.twitterHandle} />
    <meta name="twitter:title" content={metadata.title} />
    <meta name="twitter:description" content={metadata.description} />
    <meta name="twitter:image" content={`${process.env.HOST}${image.images.fallback.src}`} />
    <meta name="twitter:image:alt" content={metadata.title} />

    <link
      rel="icon"
      type="image/png"
      sizes="512x512"
      href={androidIcon512x512}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={androidIcon192x192}
    />
    <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
    <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href={safariPinnedTab} color="#000000" />
    <meta name="msapplication-TileColor" content={msIcon150x150} />
    <meta name="theme-color" content="#ffffff" />
    <script type="application/ld+json" crossorigin="anonymous">
          {JSON.stringify(config.schemaMarkup)}
    </script>
  </Helmet>
)

export default SEO

SEO.propTypes = {
  metadata: PropTypes.object.isRequired,
}