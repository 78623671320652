import React from "react"
import Seo from "./SEO"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "./Common/Navbar"
import "../assets/scss/main.scss"

export const Layout = ({ children, titlePage}) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          siteKeywords
          siteUrl
        }
      }
      file(relativePath: { eq: "og-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH)
        }
      }
    }
  `)
  
  return (
    <>
    <div className="warning">
      Unfortunately our site is not supported for IE.<br></br>
      We recommend you to use Google Chrome.
    </div>
      <Seo image={data.file.childImageSharp.gatsbyImageData} metadata={data.site.siteMetadata} titlePage={titlePage} />
        <Navbar />
        {children}
    </>
  )
}
export default Layout