/* eslint-disable react-hooks/exhaustive-deps*/
import React from "react"
import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import IconLogo from "../Icons/Logo"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState("");
  const [openContent, setOpenContent] = useState("");
  const [windowSize, setWindowSize] = useState(900);
  const docsVersion = useStaticQuery(graphql`
    query latestdocsQuery {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___version }) {
        nodes {
          frontmatter {
            version
          }
        }
      }
    }
  `)
  const latestDocsVersion = docsVersion.allMarkdownRemark.nodes[0].frontmatter.version;
  useEffect(() => {
    const handleResize = () => {
      if(windowSize <= 1023 && openContent === "is-active" && openMenu === "is-active")
      {
        setOpenMenu("is-active")
      }
      else
      {
        setOpenMenu("")
        setOpenContent("");
      }
    }
    if (typeof window !== "undefined") {
      setWindowSize(window.innerWidth);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[])
  const toggleMenuNavbar = () => {
    if (openContent === "")
    {
      setOpenContent("is-active");
      setOpenMenu("is-active");
    }
    else
    {
      setOpenContent("");
      setOpenMenu("");

    }
  }
  return (
    <div className="container is-fluid">
        <header className="navbar position-color">
              <div className="onHover">
              <div className="header-logo">
            <Link className="logo" to="/">
              <IconLogo />
            </Link><h4>Poles on Earth</h4>
          </div>
                <div onClick = {() => toggleMenuNavbar()} className={"navbar-burger " + openMenu} aria-label="menu" aria-hidden="true">
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </div>
              </div>
              <div className={"navbar-menu color " + openContent}>
                {openContent===""
                ? <div className="navbar-end">
                    <div className="position-text">
                    <h4>
                        <Link to="/">Home </Link>
                        |{" "}
                        <Link to="/demo">Demo </Link>
                        |{" "}
                        <Link to="/app">App </Link>
                        |{" "}
                        <Link to={"/docs/" + latestDocsVersion}>Docs </Link>
                        | {" "}
                        <Link to="/insights">Insights</Link>
                    </h4>
                    </div>
                  </div>
                :
                <div className="color has-text-centered">
                  <h4 className="navbar-item"><Link to="/">Home </Link></h4>
                  <h4 className="navbar-item"><Link to="/demo">Demo</Link></h4>
                  <h4 className="navbar-item"><Link to="/app">App</Link> </h4>
                  <h4 className="navbar-item"><Link to={"/docs/" + latestDocsVersion}>Docs</Link></h4>
                  <h4 className="navbar-item"><Link to="/insights">Insights</Link></h4>
                </div>
                  }
              </div>
          </header>
    </div>
  )
}
export default Navbar